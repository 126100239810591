import Box from '@mui/material/Box';
import React, { useState, useEffect } from 'react';

import {
  useAuth,
  useUser
} from "@clerk/clerk-react";

const environment = process.env.REACT_APP_ENVIRONMENT; //prod or dev

let MAIN_API_NAME = "transcript_ai_1"

let MAIN_API_URL = "https://funquiz.app/modular2/transcript-ai-1"

function UserEmailAddress() {
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded || !isSignedIn) {
    return "";{/*null;*/}
  }
  return user.primaryEmailAddress.emailAddress;
}

function FxInputForm3() {
    const [selectedFile, setSelectedFile] = useState(null);
  
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
    };
  
    //const authenticatedFetch = useFetch();
    const { getToken } = useAuth();
    const emailAddr = UserEmailAddress();

    const [textOutput, setTextOutput] = useState('');
  
    const handleTextOutputChange = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextOutput(event.target.value);
    };
  
    
    const handleUpload = () => {
      setTextOutput("Loading...");
      // Implement upload logic here, e.g., using Fetch API or a library like Axios
      if (selectedFile) {
        const formData = new FormData();
        formData.append('sound1', selectedFile);
        formData.append('a name', "my name is earl");
        formData.append('email', emailAddr);
        formData.append('env', environment);
        formData.append('api_name',MAIN_API_NAME)
        console.log(selectedFile);
  
        const fetchX = async () => {
          const myToken = await getToken();
          // Make an API request to upload the file
          fetch(MAIN_API_URL, {
            mode: 'cors',
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${myToken}`,
            },
            body: formData,
          })
          .then(response => response.json())
          .then(response => {
            // Handle response from server
            console.log(response["message"]["transcription"]);
            setTextOutput(response["message"]["transcription"]);
          })
          .catch(error => {
            // Handle error
            setTextOutput("Sorry, action failed");//error.message);
            console.error(error);
            //console.log("error")
          });
        };
        fetchX();
      }
    };
  
    return (
      <div>
        <input type="file" accept="audio/*" onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload</button>
        <Box marginTop={5} marginBottom={5}>
        <div
          style={{
            whiteSpace: "pre-line", // Preserve line breaks and spaces
            height: "300px",       // Set a fixed height
            overflow: "auto",      // Enable scrolling if content overflows
            border: "1px solid #ccc",
            padding: "10px",
          }}
        > {textOutput} </div>
        </Box>
      </div>
    );
  }

  export default FxInputForm3;