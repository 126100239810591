import logo from './logo.svg';
import './App.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';

import React, { useState, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Helmet } from "react-helmet";

import StickyFooter from './StickyFooter';
import Container from '@mui/material/Container';
import SearchAppBar from "./components/AppBar"
import BasicModal from './components/Modal';
//import AudioUpload from './components/AudioUpload';

import ResponsiveDrawer from './components/AppBar2';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  SignIn,
  SignUp,
  UserButton,
  useUser,
  useAuth,
} from "@clerk/clerk-react";
import { BrowserRouter, Route, Routes, useNavigate, currentUser } from "react-router-dom";
import FxInputForm3 from './components/FxInputForm3';

{/*import { currentUser } from '@clerk/nextjs';*/}

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}
//const clerkPubKey = "__UNSET_LIVE";//process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
// __UNSET_LIVE
// pk_test_YmFsYW5jZWQtcm91Z2h5LTkyLmNsZXJrLmFjY291bnRzLmRldiQ
let clerkPubKey;
if (process.env.REACT_APP_ENVIRONMENT == "prod")
{
  clerkPubKey = "pk_live_Y2xlcmsuYXVkaW90cmFuc2NyaXB0YWkuY29tJA";
}
else 
{
  clerkPubKey = "pk_test_dmFsdWVkLXByYXduLTQuY2xlcmsuYWNjb3VudHMuZGV2JA";
}

let STRIPE_AUDIO_TRANSCRIPT_DEV_PRODUCT_PAYMENT_URL = "https://buy.stripe.com/test_3csbJLeVD2djcOQaEH"
let STRIPE_AUDIO_TRANSCRIPT_PROD_PRODUCT_PAYMENT_URL = "https://buy.stripe.com/3cs3d56Ro1p4d7a5kn"

let STRIPE_SUBSCRIPTION_MANAGEMENT_URL = "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss"

let GENERIC_PAGE_TITLE = 'Audio Transcript AI 📝👂'
let HOME_PAGE_TITLE = 'Home - Audio Transcript AI 📝👂'
let DASHBOARD_PAGE_TITLE = 'Home - Audio Transcript AI 📝👂'

let WELCOME_MESSAGE_H1_TITLE = "Welcome to Audio Transcript AI 📝👂"

let MAIN_API_NAME = "transcript_ai_1"

const environment = process.env.REACT_APP_ENVIRONMENT; //prod or dev

console.log(process.env.REACT_APP_ENVIRONMENT);

function PublicHomePage() {
  useEffect(() => {
    // Set the title when the component mounts
    document.title = HOME_PAGE_TITLE;

    // Clean up by resetting the title when the component unmounts
    return () => {
      document.title = GENERIC_PAGE_TITLE;
    };
  }, []); // Empty dependency array ensures this effect runs only on mount and unmount


  return (
    <div>
      <h1>{WELCOME_MESSAGE_H1_TITLE}</h1>
      {/*<a href="/protected"><Button>Get Started</Button></a>*/}
      <a href="/protected"><Button>Get Started</Button></a>
      {/*<a href="/protected">Go to Protected Zone</a>*/}
    </div>
  );
}

function PublicPage() {
  return (
    <>
      <h1>Public landing page</h1>
      <a href="/protected">Go to protected page</a>
    </>
  );
}

function UserEmailAddress() {
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded || !isSignedIn) {
    return "";{/*null;*/}
  }
  return user.primaryEmailAddress.emailAddress;
}

function UserFirstName() {
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded || !isSignedIn) {
    return "";{/*null;*/}
  }
  return user.firstName;
}

function SubscriberGreeting() {
  const { isLoaded, isSignedIn, user } = useUser();
  var userEmailAddr = UserEmailAddress();
  var userFname = UserFirstName();

  return (
    <h3>You are subscribed {userFname}! ({userEmailAddr})</h3>
  );
}

function SubDiv1(props) {
  const isSubbed = props.isSubscribed;
  if (isSubbed) {
    return <SubscriberDiv1 />;
  }
  return <NonSubscriberDiv />;
}

function SubscriberDiv1() {
  return (
    <div>
      <a href="https://billing.stripe.com/p/login/28ocP200L31J9gs7ss"><p>Manage Subscription</p></a >
      <SubscriberGreeting /> {/*<Greeting isSubscribed={true}/>*/}
      <p> This f(x) can be used to generate transcripts for audio files, choose your file and then press the 'Upload' button below to get your result! </p>
      <hr />
      <FxInputForm3 />
      {/*<AudioUpload />*/}
    </div>
  )
}

function NonSubscriberDiv() {
  const { isLoaded, isSignedIn, user } = useUser();
  var userEmailAddr = UserEmailAddress();
  var userFname = UserFirstName();

  var stripeCheckoutLink = STRIPE_AUDIO_TRANSCRIPT_PROD_PRODUCT_PAYMENT_URL;
  if (environment == "dev")
  {
    stripeCheckoutLink = STRIPE_AUDIO_TRANSCRIPT_DEV_PRODUCT_PAYMENT_URL;
  }

  return (
    <div>
      <NonSubscriberGreeting />  {/*<Greeting isSubscribed={false}/>*/}
      <p>Sorry you are not subscribed, subscribe now to access the functionality!</p>
      <a href={stripeCheckoutLink + "?prefilled_email=" + userEmailAddr}><Button>Subscribe Here!</Button></a>
      {/*test: /https://buy.stripe.com/test_7sIcNP14N7xD6qs144?prefilled_email=...*/}
      {/*prod: https://buy.stripe.com/28o6ph1x4gjY3wA6oo....*/}
    </div>
  )
}

function NonSubscriberGreeting() {
  const { isLoaded, isSignedIn, user } = useUser();
  var userEmailAddr = UserEmailAddress();
  var userFname = UserFirstName();

  return (
    <h3>You are not subscribed {userFname}! ({userEmailAddr})</h3>
  );
}

function ProtectedUserPortalPage() {
  const { isLoaded, isSignedIn, user } = useUser();
  var userEmailAddr = UserEmailAddress();
  var userFname = UserFirstName();

  const [textOutput, setTextOutput] = useState(-1);
  const handleTextOutputChange = event => {
      {/*console.log('Saving value');
  console.log(event.target.value);*/}
      setTextOutput(event.target.value);
  };

  var userIsSubscribed = false;
  //const authenticatedFetch = useFetch();

  const { getToken } = useAuth();

  useEffect(()=>{
    // Set the title when the component mounts
    document.title = DASHBOARD_PAGE_TITLE;

      // do stuff here...
      {/*alert('Hello!');*/}
    var checkSubURL = 'https://funquiz.app/modular2/subscription-status';
    // var checkSubURL = 'https://funquiz.app/checksubstatus2/verify-jwt';
    
    const formData = new FormData();
    formData.append('email', userEmailAddr);
    formData.append('api_name',MAIN_API_NAME)
    formData.append('env', environment);

    const fetchX = async () => {
      const myToken = await getToken();
      fetch(checkSubURL, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${myToken}`,
        },
        body: formData,
      })
      .then(response => response.json()
      )
      .then((response) => {
        // Handle response from server
        console.log(response);
        if ("is_subscribed" in response)
        {
          const isSubbedResponse = response["is_subscribed"];
          if (isSubbedResponse == "1")
          {
            userIsSubscribed = 1;
          }
          else {
            userIsSubscribed = 0;
          }
          setTextOutput(userIsSubscribed); // textOutput is misnomer, should be substatus, this is so ui is updated
          //userIsSubscribed = parseInt(isSubbedResponse);
          //console.log(isSubbedResponse);
          //console.log(userIsSubscribed);
        }
      })
      .catch(error => {
        // Handle the error
        console.error('Error:', error);
      });
    };
    fetchX();

    return () => {
      document.title = 'Image Craft AI 🌇';
    };
  }, []) 

  let greet;
  if (textOutput == -1) {
    /* console.log(`greet1 ${textOutput}`);*/
    greet = <p>Loading...</p>;
  } 
  else {
    /* console.log("greet2 $textOutput"); */
    greet = <SubDiv1 isSubscribed={textOutput}/>;
  }

  return (
    <>
      <h1>Dashboard</h1>{/*<h1>Protected User Portal X</h1>*/}
      <UserButton />
      {/*<Greeting isSubscribed={userIsSubscribed}/>*/}
      {/*<h3>Are you subscribed {userFname}? ({userEmailAddr})</h3>*/}
      {/*non test 1: https://buy.stripe.com/28o6ph1x4gjY3wA6oo, test 2: https://buy.stripe.com/test_7sIcNP14N7xD6qs144*/}
      {/*<a href={"https://buy.stripe.com/test_7sIcNP14N7xD6qs144?prefilled_email=" + userEmailAddr}><Button>Subscribe Here!</Button></a>*/}
      {greet}
      {/*<OutputForm />*/}
      {/*<p>{textOutput}</p>*/}
    </>
  );
}

function ClerkProviderWithRoutes() {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      navigate={(to) => navigate(to)}
    >
      <Routes>
        <Route 
          path="/" 
          element={<PublicHomePage />} 
        />
        <Route
          path="/sign-in/*"
          element={<SignIn routing="path" path="/sign-in" />}
        />cy
        <Route
          path="/sign-up/*"
          element={<SignUp routing="path" path="/sign-up" />}
        />
        <Route
          path="/protected"
          element={
          <>
            <SignedIn>
              <ProtectedUserPortalPage />
            </SignedIn>
             <SignedOut>
              <RedirectToSignIn /> {/*maybe redirect to sign up*/}
           </SignedOut>
          </>
          }
        />
      </Routes>
    </ClerkProvider>
  );
}

function useFetch() {
  const { getToken } = useAuth();
  const authenticatedFetch = async (...args) => {
    const token = await getToken();
    //console.log(token);
    return fetch(...args, {
      headers: { Authorization: `Bearer nojnnononoinoinoin` }
    });//.then(res => res.json());
  };
  return authenticatedFetch;
}

function App() {
  return (
    <BrowserRouter>
      <ClerkProviderWithRoutes />
    </BrowserRouter>
  );
}


export default App;
